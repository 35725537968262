<template>
  <div class="home">
    <div class="personal-header main">
      <el-upload :action="$uploadUrl + '/system/user/profile/avatar'" :headers="token" class="avatar-uploader"
        :show-file-list="false" :before-upload="beforeUpload">
        <div class="personal-img" v-if="getUserInfo">
          <img :src="$uploadUrl + getUserInfo.avatar" alt="" />
        </div>
      </el-upload>

      <div class="personal-name">
        <div class="name">{{ getUserInfo && getUserInfo.nickName }}</div>
        <el-button type="text" @click="dialogVisible = true">修改密码</el-button>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
      <el-form ref="form" :model="user" :rules="rules" label-width="80px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model="user.oldPassword" placeholder="请输入旧密码" type="password" show-password />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="user.newPassword" placeholder="请输入新密码" type="password" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model="user.confirmPassword" placeholder="请确认密码" type="password" show-password />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="submitPwd">保存</el-button>
          <el-button type="danger" size="mini" @click="dialogVisible = false">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 
         -->
    <div class="personal-nav main">
      <el-dropdown trigger="click" class="nav-item" v-for="(item, index) in cateList" :key="index"
        :class="item.id == navIndex ? 'act' : ''" @click.native="getCount(item.id)" @command="handleCommand">
        <span class="el-dropdown-link">
          <span class="mr17"> </span>
          <span> {{ item.name }}</span><i class="el-icon-arrow-down el-icon--right"></i>
          <span v-if="item.id == navIndex">
            <el-tag v-if="statusTxt == 9">{{ $t('add.all') }}</el-tag>
            <el-tag type="warning" v-if="statusTxt == -1"> {{ $t('details.auditStatus1') }}</el-tag>
            <el-tag type="danger" v-if="statusTxt == 0">{{ $t('details.auditStatus2') }}</el-tag>
            <el-tag type="success" v-if="statusTxt == 2">{{ $t('details.auditStatus3') }}</el-tag>
            <el-tag v-if="statusTxt == 1">{{ $t('details.auditStatus4') }}</el-tag>
          </span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="9">{{ $t('add.all') }}</el-dropdown-item>
          <el-dropdown-item command="-1"> {{ $t('details.auditStatus1') }}({{ auditCount.rejected || 0 }})
          </el-dropdown-item>
          <el-dropdown-item command="0">{{ $t('details.auditStatus2') }}({{ auditCount.unreviewed || 0 }})
          </el-dropdown-item>
          <el-dropdown-item command="2">{{ $t('details.auditStatus3') }}({{ auditCount.underReview || 0 }})
          </el-dropdown-item>
          <el-dropdown-item command="1">{{ $t('details.auditStatus4') }}({{ auditCount.pass || 0 }})</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="nav-item" :class="5 == navIndex ? 'act' : ''" @click="navIndex = 5">{{ $t('details.myComment') }}
      </div>
      <div class="nav-item" :class="6 == navIndex ? 'act' : ''" @click="getMyFavorite">{{ $t('details.myCollect') }}
      </div>
      <div class="nav-item" :class="9 == navIndex ? 'act' : ''" @click="navIndex = 9">{{ $t('details.myResource') }}
      </div>
      <div class="nav-item" :class="7 == navIndex ? 'act' : ''" @click="navIndex = 7">{{ $t('details.history') }}</div>
      <div class="nav-item" :class="8 == navIndex ? 'act' : ''" @click="getEditList">{{ $t('details.update') }}</div>
    </div>

    <div class="main" style="height: 600px" v-if="navIndex < 5">
      <my-no-data v-if="showList.length == 0" :conentMinHeight="700" conentTop="50%"></my-no-data>
      <ImgItem v-else :imgList="showList" :total="total" :type="navIndex"></ImgItem>
    </div>

    <div class="main" v-if="navIndex == 5">
      <my-remark></my-remark>
    </div>

    <div class="main" v-if="navIndex == 7">
      <history></history>
    </div>

    <div class="main" v-if="navIndex == 8">
      <update></update>
    </div>

    <div class="main" v-if="navIndex == 9">
      <resource></resource>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user';
import ImageApi from '@/api/image';
import MyNoData from '@/components/noData/index.vue';
import MyRemark from '@/components/userComponents/remake.vue';
import update from '../../components/userComponents/update.vue';
import resource from '@/components/userComponents/resource.vue';
import ImgItem from './ImgItem';
import { getToken, setUserInfo } from '@/utils/auth';
import { mapGetters, mapMutations } from 'vuex';
import History from '../../components/userComponents/history.vue';

export default {
  name: 'myindex',
  components: {
    ImgItem,
    MyNoData,
    MyRemark,
    History,
    update,
    resource
  },
  data () {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    return {
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      },
      // 表单校验
      rules: {
        oldPassword: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { required: true, validator: equalToPassword, trigger: 'blur' },
        ],
      },
      navIndex: 1,
      auditCount: {},
      cateList: [
        {
          name: '图片',
          id: 1,
        },
        {
          name: '3D模型',
          id: 2,
        },
        {
          name: '全景图',
          id: 3,
        },
        {
          name: '断层扫描',
          id: 4,
        },
      ],
      showList: [],
      token: {},
      statusTxt: 9,
      dialogVisible: false,
      total: 0,
      from: 1,
    };
  },
  computed: {
    ...mapGetters(['getUserInfo']),
  },
  created () {
    this.token = {
      Authorization: getToken(),
    };
  },
  mounted () {
    this.getShowList(9);
  },
  methods: {
    getData () {
      this.from++;
      this.getShowList(this.statusTxt);
    },
    getEditList () {
      this.navIndex = 8;
    },
    submitPwd () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          userApi.updateUserPwd(this.user.oldPassword, this.user.newPassword).then((response) => {
            this.dialogVisible = false;
            this.$message({
              message: '修改成功',
              type: 'success',
            });
          });
        }
      });
    },
    ...mapMutations(['SET_USER']),
    async getMyFavorite () {
      this.navIndex = 6;
      const { rows: res } = await userApi.getFavorite();
      this.showList = res;
    },
    async getUser_Info () {
      const { user: res } = await userApi.getUserInfo();
      this.SET_USER(res);
      setUserInfo(res);
    },

    beforeUpload (file) {
      const timeStamp = new Date() - 0;
      const copyFile = new File([file], `${timeStamp}_${file.name}`);
      this.uploadFile(copyFile);
      return false;
    },

    async uploadFile (file) {
      const formdata = new FormData();
      formdata.append('avatarfile', file);
      const data = await userApi.avatar(formdata);
      if (data.code == 200) {
        this.getUser_Info();
        this.$message({
          message: '上传成功',
          type: 'success',
        });
      }
    },

    async getCount (type) {
      if (type == 5 || type == 6) {
        return (this.navIndex = e);
      }
      this.navIndex = type;
      this.statusTxt = 9;
      // this.getShowList(this.statusTxt, type);
      const { data: res } = await userApi.auditCount(type);
      if (typeof res == 'object') {
        this.auditCount = res;
      } else {
        this.auditCount = {};
      }
    },
    async handleCommand (e) {
      this.statusTxt = e;
      this.showList = [];
      this.from = 1;
      this.showList = [];
      this.getShowList(e);
    },
    async getShowList (e) {
      let obj = {
        auditStatus: e,
        pageNum: this.from,
        pageSize: 20,
      };
      if (e == 9) {
        obj = {
          pageNum: this.from,
          pageSize: 20,
        };
      }
      if (this.navIndex == 1) {
        const { rows: res } = await ImageApi.imgListInfo(obj);
        this.showList.push(...res);
        this.total = res.total;
      }
      if (this.navIndex == 2) {
        const { rows: res } = await ImageApi.modelListInfo(obj);
        this.showList.push(...res);
        this.total = res.total;
      }
      if (this.navIndex == 3) {
        const { rows: res } = await ImageApi.panoramaListInfo(obj);
        this.showList.push(...res);
        this.total = res.total;
      }
      if (this.navIndex == 4) {
        const { rows: res } = await ImageApi.tomographyListInfo(obj);
        this.showList.push(...res);
        this.total = res.total;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.remarkBox {
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 2px;
  height: 105px;
  margin: 10px 0;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);

  .content {
    height: 40px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .doing {
    display: flex;

    .name {
      height: 20px;
      font-size: 14px;

      font-weight: 400;
      color: #68adf7;
    }

    .mr81 {
      margin-right: 81px;
    }

    .date {
      height: 20px;
      font-size: 14px;

      font-weight: 400;
      color: #999999;
    }

    .cur {
      cursor: pointer;
    }
  }
}

::-webkit-scrollbar {
  width: 0 !important;
}

.personal-header {
  position: relative;
  margin: 0 auto;
  //   overflow: hidden;
  height: 142px;
  margin-top: 83px;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);

  .personal-img {
    position: absolute;
    bottom: 25px;
    left: 30px;
    width: 180px;
    height: 180px;
    background: #ffffff;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 3px;
    box-sizing: border-box;
    border: 1px solid #ffffff;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .personal-name {
    position: absolute;
    bottom: 20%;
    left: 20%;

    .name {
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      margin-bottom: 18px;
    }
  }
}

.personal-nav {
  margin: 0 auto;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;

  .nav-item {
    line-height: 50px;
    margin: 0 20px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    color: #888888;

    .mr17 {
      margin-right: 17px;
    }
  }

  .act {
    font-weight: 600;
    color: #333333;
  }
}

.personal-remark {
  margin-top: 22px;
  padding-bottom: 30px;
}

.personal-upload {}
</style>
