<template>
  <div style="min-height: 700px">
    <my-no-data v-if="txtList.length == 0" style="height: 500px;" conentTop="50%"></my-no-data>
    <div class="remarkBox" v-for="(item, index) in txtList" :key="index" v-else>
      <div class="content" @click="goDetail(item)">
        {{ item.content || '系统默认' }}
      </div>
      <div class="doing">
        <div class="name mr81">#{{ item.infoName }}#</div>
        <div class="date mr81">
          {{ item.createTime }}
        </div>
        <div class="date cur" @click="del(item.commentId)">删除</div>
      </div>
    </div>
  </div>
</template>

<script>
import MyNoData from "../noData/index.vue";
// import { mapGetters } from "vuex";
import commentApi from "@/api/comment";
import { getUserInfo } from "@/utils/auth";

export default {
  components: {
    MyNoData,
  },
  name: "remarkBox",
  computed: {},
  data () {
    return {
      txtList: [],
    };
  },
  methods: {
    async init () {
      if (getUserInfo().userId) {
        const { rows: res } = await commentApi.commentList({ fromUid: getUserInfo().userId });
        this.txtList = res;
      }
    },
    del (id) {
      this.$confirm("是否删除评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await commentApi.delComment(id);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.init();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    goDetail (e) {
    },
  },
  created () { },
  mounted () {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.remarkBox {
  padding: 15px 30px;
  box-sizing: border-box;
  margin-bottom: 2px;
  height: 105px;
  margin: 10px 0;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);

  .content {
    height: 40px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .doing {
    display: flex;

    .name {
      height: 20px;
      font-size: 14px;

      font-weight: 400;
      color: #68adf7;
    }

    .mr81 {
      margin-right: 81px;
    }

    .date {
      height: 20px;
      font-size: 14px;

      font-weight: 400;
      color: #999999;
    }

    .cur {
      cursor: pointer;
    }
  }
}
</style>
