<template>
  <div class="home">
    <div class="all">
      <div class="list">
        <div class="single" v-for="item in relist" @click.stop="editre(item.resourceId,'look')">
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="description">{{ item.description }}</div>
            <div class="bottom">
              <div class="key" v-if="item.keyword.length>0">
                <span v-for="tag in item.keyword">{{ tag }}</span>
              </div>
              <div v-else></div>
              <div>
                <el-button type="primary" icon="el-icon-edit" size="small" class="btn" @click.stop="editre(item.resourceId,'edit')">编辑</el-button>
                <el-button type="primary" icon="el-icon-delete" size="small" class="btn btn2"
                           @click.stop="delre(item.resourceId)">删除</el-button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { relist } from "@/api/resource"
import { mapGetters } from "vuex";
export default {
  name: '',
  components: {

  },
  // 定义属性
  data() {
    return {
      relist: [],
      searchForm: {
        createBy: ''
      }
    }
  },
  
  // 计算属性，会监听依赖属性值随之变化
   computed: {
    ...mapGetters(['getUserInfo']),
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async init() {
      this.searchForm.createBy = this.getUserInfo.createBy
      let data = await relist(this.searchForm)
      console.log('data', data);
      if (data.code == 200) {
        this.relist = data.rows.map(v => {
          if(v.keyword){
            v.keyword = v.keyword.split(',')
          }else {
            v.keyword = []
          }
          
          return v
        })
        this.tota = data.total
      }
    },
    async editre(id,type) {
      this.$router.push({
        path: 'upresource',
        query:{
          id,
          type
        }
      });
    },
    async delre() {

    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.home {
  // background: #EDF4F8;
  // box-sizing: border-box;
  // padding-top: 25px;
  // padding-bottom: 80px;
  // overflow-y: auto;
}

.all {
  width: 100%;
  margin: 0 auto;




  .list {
    margin-top: 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

    .single {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;

      .content {
        padding: 20px 35px;
        box-sizing: border-box;
        position: relative;
        height: 180px;

        .title {
          font-weight: 600;
          font-size: 18px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .description {
          margin-top: 10px;
          font-weight: 400;
          font-size: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;


        }

        .bottom {
          width: 95%;
          position: absolute;
          left: 35px;
          bottom: 20px;
          display: flex;
          justify-content: space-between;

          .key {
            span {
              display: inline-block;
              padding: 5px 15px;
              background: #DCF1FF;
              border-radius: 15px;
              border: 1px solid #89B0FF;
              font-weight: 400;
              font-size: 12px;
              color: #67ADF7;
              margin-right: 10px;
            }
          }

          .btn {
            background: #67ADF7;
            border-radius: 4px;
            border: none;
          }

          .btn2 {
            background: #F76767;
          }
        }
      }
    }
  }
}
</style>