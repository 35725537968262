<template>
  <div class="personal-ImgList" ref="element">
    <!-- <vue-flex-waterfall :col="5" :break-by-container="true" col-spacing="10"> -->
    <vue-waterfall-easy :imgsArr="imgList" srcKey="thumbnailUrl" @click="goDetails" :maxCols="6"
      @scrollReachBottom="getData" :imgWidth="210" ref="waterfall">
      <div class="img-info" slot-scope="props">
        <div class="text">
          <div class="name">
            <span v-html="props.value.imageTitle"></span>
          </div>
          <div class="enName">
            <span v-html="props.value.speciesEn"></span>
          </div>
          <div class="useTxt"
            v-if="$i18n.locale == 'en-us' && props.value.ageEn && props.value.topicType != 3 && props.value.topicType != 4">
            age:
            <span v-html="props.value.ageEn"></span>
          </div>
          <div class="useTxt" v-else-if="props.value.age && props.value.topicType != 3 && props.value.topicType != 4">
            时代:
            <span v-html="props.value.age"></span>
          </div>

          <div class="useTxt"
            v-if="$i18n.locale == 'en-us' && props.value.localityEn && props.value.topicType != 3 && props.value.topicType != 4">
            location:
            <span v-html="props.value.localityEn"></span>
          </div>
          <div class="useTxt"
            v-else-if="props.value.locality && props.value.topicType != 3 && props.value.topicType != 4">
            产地：
            <span v-html="props.value.locality"></span>
          </div>
        </div>
      </div>
    </vue-waterfall-easy>
  </div>
</template>

<script>
import VueFlexWaterfall from "vue-flex-waterfall";
import vueWaterfallEasy from "vue-waterfall-easy";
import { mapGetters, mapMutations } from "vuex";
import commonApi from "@/api/common/index";

export default {
  name: "imgItem",
  components: {
    VueFlexWaterfall,
    vueWaterfallEasy,
  },
  props: {
    imgList: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },

  data () {
    return {
      from: "",
    };
  },
  computed: {
    ...mapGetters(["getPageList", "getTag", "getTitle", "getCondition"]),
  },
  mounted () {
    // this.getData();
  },
  methods: {
    ...mapMutations(["SET_PAGE_LIST", "SET_TAG", "DEL_TAG", "SET_SEARCH", "ADD_PAGE_LIST", "SET_TITLE"]),

    async getData () {
      this.$parent.getData();
      if (this.getPageList.length == this.total) {
        this.$refs.waterfall.waterfallOver();
      }
    },

    goAdd (item) {
      if (this.type == 1) {
        this.$router.push({
          path: "addimg",
          query: {
            id: item.infoId,
          },
        });
      }
      if (this.type == 2) {
        this.$router.push({
          path: "add3D",
          query: {
            id: item.model3DId,
            auditId: item.auditId,
          },
        });
      }
      if (this.type == 3) {
        this.$router.push({
          path: "addPanorama",
          query: {
            id: item.panoramaId,
          },
        });
      }
      if (this.type == 4) {
        this.$router.push({
          path: "addTomography",
          query: {
            id: item.tomographyId,
          },
        });
      }
    },
    goDetails (e, { index, value }) {
      if (this.type && this.type != 6 && this.type != 8) {
        return this.goAdd(value);
      }
      if (this.type != 8) {
        if (value.topicType == 1) {
          this.$router.push({
            path: "imgInfo",
            query: {
              id: value.topicId,
            },
          });
        }
        if (value.topicType == 2) {
          this.$router.push({
            path: "3dInfo",
            query: {
              id: value.topicId,
            },
          });
        }
        if (value.topicType == 3) {
          this.$router.push({
            path: "panoramaInfo",
            query: {
              id: value.topicId,
            },
          });
        }
        if (value.topicType == 4) {
          this.$router.push({
            path: "tomographyInfo",
            query: {
              id: value.topicId,
            },
          });
        }
      }
    },
  },
  created () { },
};
</script>
<style lang="scss">
::v-deep.img-wraper {
  border-radius: 12px 12px 0px 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0;
    display: block;
  }
}

::-webkit-scrollbar {
  width: 0 !important;
}

.vue-waterfall-easy {
  height: 100%;
  width: 100% !important;
  // margin: 0 auto;
  // margin-left: -750px !important;
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0 !important;
}

.personal-ImgList {
  width: 100%;
  margin-top: 22px;
  height: 100%;
  padding: 1px;
  // box-sizing: border-box;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
}

.text {
  box-sizing: border-box;
  padding: 10px 10px 20px 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  .name {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
  }

  .enName {
    font-style: italic;
    font-size: 14px;
    font-family: Arial-BoldItalicMT, Arial;
    font-weight: 600;
    color: #333333;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .useTxt {
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: 600;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
  }
}

.chunk {
  width: 220px;
  background: #ffffff;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  margin-bottom: 10px;
  box-sizing: border-box;
  min-height: 300px;

  .img {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px 12px 0px 0;
      display: block;
    }
  }
}
</style>
